<template>
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0310059 2.72709V11.9797C0.0310059 13.4344 1.21667 13.9624 2.01371 13.9624H10.6055C11.3355 13.9624 11.9273 13.3706 11.9273 12.6406H2.02164C1.71631 12.6327 1.35281 12.5124 1.35281 11.9797C1.35281 11.447 1.71631 11.3268 2.02164 11.3188H11.9273V2.06619C11.9273 1.33721 11.3344 0.744385 10.6054 0.744385H2.01371C1.21667 0.744385 0.0310059 1.27245 0.0310059 2.72709Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSpaces'
}
</script>
